.main {
  width: 95px;
  height: 50px;
  border: none;
  background: transparent;
  border: 2px solid rgba(178, 235, 242, 0.9);
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.8);
  position: relative;
  cursor: pointer;
  & > button {
    font-size: 20px;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    text-align: left;
    padding-left: 10px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 22px;
      right: 10px;
      width: 10px;
      height: 2px;
      transition: all 0.3s ease-out;
      background-color: #bdbdbd;
      transform: translate(-3px, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(3px, -50%) rotate(-45deg);
    }
  }
  @media (max-width: 440px) {
    height: 40px;
    & > button {
      &::before,
      &::after {
        top: 18px;
      }
    }
  }
}

.selectOpened {
  &::before,
  &::after {
    transform: translate(-3px, -50%) rotate(-45deg) !important;
  }

  &::after {
    transform: translate(3px, -50%) rotate(45deg) !important;
  }
}

.optionsBox {
  z-index: 1;
  position: absolute;
  display: none;
  background: white;
  width: fit-content;
  height: 200px;
  overflow-y: scroll;
  top: 50px;

  & > p {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    border-bottom: 1px solid rgba(81, 80, 80, 0.2);
    padding: 0 10px;
    height: 50px;
    transition: background 0.2s;

    &:hover {
      background: rgba(225, 225, 225, 0.5);
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}
