
.loginForm {
   width: 400px;
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  label {
      display: block;
      margin-bottom: 10px;
      text-align: left;
   }
   input {
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 3px;
   }
   h2 {
      margin-bottom: 20px;
   }
   button {
      background-color: #0074cc;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 3px;
      cursor: pointer;
   }
   button:hover {
      background-color: #0056a3;
   }
}

.loggedIn form {
  display: none;
}

