@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dropDown {
  border: 1px solid #6b6b6b;
  background: white;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 600px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 15px 10px;
  animation: open 0.3s;
  z-index: 1;
}
