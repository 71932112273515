@import "../../App.scss";

.main {
  @extend .container;
  background: rgba(178, 235, 242, 0.35);
  height: 341px;
  border-radius: 37px;
  padding: 50px 100px 0 35px;
  color: #212121;
  @media (max-width: 1030px) {
    border-radius: 0;
    padding: 30px 20px;
    max-width: 100vw;
  }
  @media (max-width: 440px) {
    height: auto;
    padding: 20px 0;
  }
}
.heading_container {
  @media (max-width: 440px) {
    max-width: 350px;
    margin: 0 auto;
  }
}

.contactsBox {
  display: flex;
  justify-content: space-between;
  padding-left: 28px;
  margin-top: 58px;
  @media (max-width: 1030px) {
    padding-left: 0;
  }
  @media (max-width: 440px) {
    max-width: 350px;
    margin: 30px auto 0;
    flex-direction: column;
  }
}

.contactBox {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  & > p {
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    color: #757575;
  }
  &:last-child > p {
    width: 150px;
  }
  @media (max-width: 440px) {
    margin-bottom: 10px;
    font-size: 18px;
  }
}

.scheduleBox {
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  color: #757575;
}

.imageBox {
  background: white;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1030px) {
    width: 65px;
    height: 65px;
    margin-right: 5px;
  }
}
