@import "../../App.scss";

/* Удаление подчеркивания и изменение цвета для обычной ссылки */
a {
  text-decoration: none;
  color: black;
}

/* Изменение цвета ссылки после клика */
a:visited {
  color: black;
}

.main {
  @extend .container;
  font-family: Inter;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 51px;

  @media (max-width: 440px) {
    margin-top: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 72%;
  padding-left: 50px;
  transition: all 0.2s;
  @media (max-width: 1030px) {
    margin-top: 20px;
    padding-left: 20px;
  }
  @media (max-width: 440px) {
    padding-left: 14px !important;
    width: 100vw;
    flex-direction: column;
    align-items: end;
    display: none;
    background: white;
    z-index: -1;
    padding-bottom: 20px;
    padding-left: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 8px 8px;
    background: rgba(178, 235, 242, 1);
    position: absolute;
    width: 380px;
    height: 500px;
    padding-top: 280px;
    padding-right: 185px;
    padding-bottom: 80px;
    z-index: 2;
    border-radius: 50%;
    right: -180px;
    top: -220px;
  }
}

.change_bg {
  display: flex;
  animation: slide-in-top 0.3s;
  animation-fill-mode: forwards;
}

.heading {
  font-weight: 700;
  width: 180px;
  text-align: left;
}



.contactBox {
  display: flex;
  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    -webkit-tap-highlight-color: transparent;
    border-radius: 50%;
    & > img {
      width: 60px;
      height: 60px;
    }

    &:last-of-type {
      margin-right: 0;
    }
    @media (max-width: 1030px) {
      width: 35px;
      height: 35px;
      margin-right: 10px;
      &:last-of-type {
        margin-right: 10px;

        margin-right: 0;
      }
      & > img {
        width: 40px;
        height: 40px;
      }
    }
  }
  @media (max-width: 440px) {
    width: auto;
    order: 1;
    & > a {
      margin-right: 0;
      & > img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
.citySelector {
  border: none;
  background: transparent;
  position: relative;
  font-size: 24px;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
  width: 160px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    right: -20px;
    width: 10px;
    height: 2px;
    transition: all 0.3s ease-out;
    background-color: #bdbdbd;
    top: 50%;
    transform: translate(-50%, 0) translate(3px, 0) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, 0) translate(-3px, 0) rotate(45deg);
  }
  @media (max-width: 1030px) {
    font-size: 22px;
  }
  @media (max-width: 440px) {
    font-size: 20px;
    margin-right: 20px;
    color: #3d3d3d;
  }
}
.citySelectorOpen {
  &::before {
    transform: translate(-50%, 0) translate(3px, 0) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, 0) translate(-3px, 0) rotate(-45deg);
  }
}
.phone {
  font-weight: 600;
  font-size: 20px;
  text-decoration: none;
  color: #212121;
  @media (max-width: 440px) {
    font-size: 18px;
    color: #3d3d3d;
  }
}

.menuBtn {
  -webkit-tap-highlight-color: transparent;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  margin-top: 30px;
  display: none;
  z-index: 2;
  @media (max-width: 440px) {
    display: block;
  }
}

.menuBtn__burger {
  width: 40px;
  height: 4px;
  background: black;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.menuBtn__burger::before,
.menuBtn__burger::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 4px;
  background: black;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.menuBtn__burger::before {
  transform: translateY(-12px);
}
.menuBtn__burger::after {
  transform: translateY(12px);
}
.menuBtn.open .menuBtn__burger {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}
.menuBtn.open .menuBtn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}
.menuBtn.open .menuBtn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}
