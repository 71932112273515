@import "../AttachPhoto/AttachPhoto.module.scss";

.main {
  padding: 65px 44px 85px;
  @media (max-width: 440px) {
    padding: 40px 20px;
  }
}

.subtext {
  &:first-of-type {
    text-align: center;
    max-width: 400px;
  }
}

.fieldName {
  margin-left: 50px;
  @media (max-width: 440px) {
    margin-left: 15px;
  }
}

.errorMessage {
  color: red;
}
