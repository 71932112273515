@import "../../App.scss";

path {
  transition: all 0.3s;
}
path:hover {
  cursor: pointer;
  fill: rgba(255, 87, 34, 0.85);
  stroke: black;
}

.svgContainer {
  & > svg {
    width: 100%;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
}


