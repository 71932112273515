@import "../../App.scss";

.name {
  font-size: 24px;
  font-weight: 400;
  line-height: 25px;
  @media (max-width: 440px) {
    font-size: 20px;
  }
}


.priceContainer {
  margin-top: 40px;
  margin-bottom: 183px;
  @media (max-width: 440px) {
    margin-bottom: 81px;
    margin-top: 20px;
  }
}

.innerName {
  position: absolute;
  top: 40px;
  left: 39px;
  &:last-of-type {
    top: 65px;
  }
  @media (max-width: 1030px) {
    position: static;
  }
}

.line {
  margin-top: 73px !important;
  border: none;
  border-top: 2px solid #0097a7;
  border-bottom: 2px solid #0097a7;
  height: 179px;
  width: 1298px;
  margin: 0 auto;
  position: relative;
  @media (max-width: 1030px) {
    margin-top: 35px !important;
    height: 100px;
    width: 800px;
    padding: 0 1%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #0097a7;
    border-bottom: 1px solid #0097a7;
  }
  @media (max-width: 440px) {
    height: auto;
    width: auto;
    align-items: start;
    flex-direction: column;
    max-width: 350px;
    padding: 20px 0;
  }
  & > img {
    position: absolute;
    width: 500px;
    height: 460px;
    bottom: -630px;
    right: 21px;
    @media (max-width: 440px) {
      display: none;
    }
  }
}

.priceBox{
  display: flex;
  justify-content: space-between;
  @media (max-width: 440px) {
    flex-direction: column;
  }
}

.lineBox {
  @extend .container;
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
  @media (max-width: 1030px) {
    max-width: auto;
    margin: 0;
    padding: 0;
  }
}

.calculateButton {
  background: white !important;
  font-size: 24px;
  font-weight: 700;
  background: transparent;
  border: none;
  width: 342px;
  height: 66px;
  border-radius: 30px;
  box-shadow: 3px 3px 15px 0px #00000040;
  &:last-of-type {
    z-index: 2;
  }
  @media (max-width: 440px) {
    height: 50px;
    font-size: 22px;
    font-weight: 500;
    width: fit-content;
    padding: 0 20px;
    margin-top: 20px;
  }
}

.imageMobile {
  display: none;

  @media (max-width: 440px){
    display: block;
    width: 360px;
  }

}