* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

@font-face {
  font-family: "Manrope";
  src: local("Manrope"), url("./fonts/Manrope-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: Manrope, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  width: 10px;
  padding: 0 1px;
}

body::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb {
  border: 2px solid white;
  border-radius: 10px;
  background: rgba(255, 87, 34, 0.85);
}
