.button {
  cursor: pointer;
  border: none;
  border-radius: 45px;
  background: rgba(255, 87, 34, 0.85);
  box-shadow: 3px 3px 15px 0px #00000040;
  color: white;
  font-size: 24px;
  height: 66px;
  @media (max-width: 440px) {
    width: auto !important;
    font-size: 22px;
    height: 50px;
    padding: 0 20px;
  }
}
