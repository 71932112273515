.selectionBox {
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    top: 15px;
    right: -15px;
    width: 10px;
    height: 2px;
    transition: all 0.3s ease-out;
    background-color: #bdbdbd;
    transform: translate(-3px, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(3px, -50%) rotate(-45deg);
  }

  @media (max-width: 440px) {
    margin-right: 20px;
  }
}

.select {
  background: transparent;
  font-size: 24px;
  color: #212121;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  @media (max-width: 1030px) {
    font-size: 22px;
  }
  @media (max-width: 440px) {
    font-size: 20px;
    color: #3d3d3d;
  }
}
