@import "../../App.scss";

.header {
  display: flex;
  border-bottom: 1px solid #000;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 0 150px;
  width: 80%;
  margin: 0 auto;
}
.header > a {
  text-decoration: none;
  color: rgb(77, 76, 76);
}

:global .active {
  color: rgb(0, 0, 0) !important;
  font-weight: 600;
}

.spinnerBox {
  display: flex;
  justify-content: center;
}

.titlesAdminPanel {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.formsAdminPanel {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
  height: 75px;
  padding: 5px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
}

.buttonAdminPanel {
  background-color: #0074cc;
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  padding: 10px 20px;
}