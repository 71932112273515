@import "../../App.scss";

.description {
  font-weight: 300;
  font-size: 20px;
  color: #757575;
  line-height: 27px;
  &:last-of-type {
    margin-bottom: 50px;
  }
  @media (max-width: 440px) {
    font-size: 18px;
    line-height: 20px;
  }
}

.servicesContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  @media (max-width: 1030px) {
    column-gap: 20px;
  }
  @media (max-width: 440px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    row-gap: 15px;
  }
}

.serviceBox {
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  padding: 39px 25px;
  @media (max-width: 1030px) {
    height: 361px;
  }
  @media (max-width: 440px) {
    height: auto;
    background-repeat: 8px;
    padding: 20px 12px;
  }
}

.serviceBoxCenter {
  border: 2px solid #bdbdbd;
  border-radius: 10px;
  padding: 39px 25px;
  @media (max-width: 1030px) {
    height: 370px;
  }
  @media (max-width: 440px) {
    height: auto;
    background-repeat: 8px;
    padding: 20px 12px;
  }
}

.serviceHeading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #757575;
  @media (max-width: 1030px) {
    font-size: 20px;
  }
}

.serviceHeadingCenter{
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
  @media (max-width: 1030px) {
    font-size: 20px;
  }
}

.serviceName {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  font-size: 18px;
  color: #757575;
}

.serviceNameCenter {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  font-size: 18px;
}

.buttonBox {
  margin-top: 65px;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 440px) {
    margin-top: 40px;
  }
}
