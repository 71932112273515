.footer {
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  width: fit-content;
  margin: 51px auto 47px;
  color: #757575;
  @media (max-width: 1030px) {
    margin: 20px auto 20px;
  }
  @media (max-width: 440px) {
    width: 250px;
    text-align: center;
  }
}
