@mixin modal-window {
  width: 675px;
  border-radius: 30px;
  background: white;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.35);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 47px 69px 48px;
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  & > h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
    margin-bottom: 4px;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  & > button {
    flex-shrink: 0;
  }
  @media (max-width: 440px) {
    width: 100vw;
    height: 70vh;
    max-height: 100vh;
    padding: 40px 20px;
    border-radius: 0;
  }
}

@mixin input-box {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.8);
  border: none;
  background: rgba(178, 235, 242, 0.25);
  margin-bottom: 20px;
  width: 586px;
  height: 75px;
  border-radius: 37px;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.35);
  padding: 0 56px;
  flex-shrink: 0;
  & > input {
    &:last-child {
      margin-bottom: 40px;
    }
    &::placeholder {
      padding-left: 5px;
      font-size: 20px;
      font-weight: 400;
      line-height: 34px;
      color: #757575;
    }
  }
  @media (max-width: 440px) {
    width: 100%;
    height: 55px;
    padding: 0 26px;
    & > input {
      &::placeholder {
        padding-left: 0;
      }
    }
  }
}

@mixin sub-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #757575;
  &:first-of-type {
    margin-bottom: 31px;
  }
  &:last-of-type {
    width: 380px;
    text-align: center;
    margin-bottom: 37px;
  }
  @media (max-width: 440px) {
    font-size: 18px;
    width: 90% !important;
    text-align: center;
  }
}

.phoneBox {
  background: rgba(178, 235, 242, 0.25);
  margin-bottom: 20px;
  width: 586px;
  height: 75px;
  border-radius: 37px;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.35);
  padding-left: 40px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  & > input {
    color: rgba(0, 0, 0, 0.8);
    margin-left: 10px;
    font-size: 22px;
    height: 100%;
    border: none;
    background: transparent;
  }
  @media (max-width: 440px) {
    width: 100%;
    height: 55px;
    padding-left: 20px;
    & > input {
      width: 200px;
    }
  }
}

.main {
  @include modal-window;
  position: relative;
  & > input {
    @include input-box;
  }
}

.filesBox {
  position: relative;
  display: flex;
  align-items: center;
  @include input-box;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  color: #757575;
  & > label {
    display: flex;
    align-items: center;
    right: 69px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    cursor: pointer;
  }
  @media (max-width: 440px) {
    & > label {
      right: 26px;
    }
  }
}

.fileNamesBox {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding-top: 15px;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
    & > img {
      cursor: pointer;
      height: 30px;
      filter: invert(84%) sepia(11%) saturate(0%) hue-rotate(174deg)
        brightness(90%) contrast(87%);
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
  @media (max-width: 440px) {
    padding-top: 8px;
    & > div {
      margin-bottom: 8px;
    }
  }
}

.file {
  padding-right: 100px;
}

.fileName {
  width: 100%;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.8);
  flex-shrink: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 30px;
  margin-bottom: 8px;
  @media (max-width: 440px) {
    font-size: 18px;
  }
}

.fieldName {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  margin-bottom: 10px;
  @media (max-width: 440px) {
    font-size: 18px;
    margin-bottom: 5px;
    margin-left: 10px;
    line-height: 15px;
  }
  & > span {
    color: #ff5722;
  }
}
.subtext {
  @include sub-text;
}
