@import "../../App.scss";

.main {
  @extend .container;
  margin-top: 104px;
  margin-bottom: 213px;
  @media (max-width: 440px) {
    margin-top: 81px;
    margin-bottom: 81px;
  }
}

.pipeLine {
  margin-top: 124px;
  border-top: 3px solid #0097a7;
  width: 1062px;
  position: relative;
  @media (max-width: 1030px) {
    width: auto;
    border: none;
    margin-top: 40px;
  }
  @media (max-width: 440px) {
    margin-top: 20px;
  }
}
.stageBox {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -24px;

  &:nth-child(2) {
    left: 338px;
  }
  &:nth-child(3) {
    left: 676px;
  }
  &:nth-child(4) {
    left: 1014px;
  }
  @media (max-width: 1030px) {
    position: static;
    flex-direction: row;
    margin-bottom: 10px;
    align-items: center;
  }
}
.number {
  width: 48px;
  height: 48px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #0097a7;
  font-weight: 600;
  font-size: 24px;
  color: white;
  @media (max-width: 1030px) {
    width: 42px;
    height: 42px;
    font-size: 20px;
  }
}
.stageName {
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  width: 250px;
  margin-left: 24px;
  @media (max-width: 1030px) {
    width: auto;
    margin-left: 15px;
  }
  @media (max-width: 440px) {
    line-height: 22px;
    font-size: 20px;
  }
}
