.heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  width: fit-content;
  white-space: pre-line;
  @media (max-width: 440px) {
    font-size: 26px;
    font-weight: 500;
    line-height: 26px;
  }
}
