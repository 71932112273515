@import "../../App.scss";

.main {
  height: 572px;
  margin-top: 101px;
  position: relative;
  @media (max-width: 440px) {
    margin-top: 40px;
    height: auto;
  }
}

.box {
  @extend .container;
  margin-bottom: 81px;
}

.subHeading {
  font-size: 20px;
  color: #757575;
  margin-bottom: 50px;
  @media (max-width: 440px) {
    margin-bottom: 30px;
  }
}

.bearMobile {
  display: none;
  @media (max-width: 440px) {
    display: block;
    width: 350px;
    margin-bottom: 10px;
  }
}

.description {
  font-weight: 400;
  font-size: 24px;
  color: #212121;
  margin-top: 30px;
  margin-bottom: 110px;
  @media (max-width: 440px) {
    margin-bottom: 30px;
    font-size: 20px;
  }
}

.attachButton {
  cursor: pointer;
  border: none;
  border-radius: 45px;
  background: rgba(255, 87, 34, 0.85);
  box-shadow: 3px 3px 15px 0px #00000040;
  color: white;
  font-size: 24px;
  width: 388px;
  height: 66px;
  margin-top: 110px;
}

.right {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  & > img {
    width: 500px;
    height: 475px;
    @media (max-width: 440px) {
      display: none;
    }
  }
}

.descriptionContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  justify-content: space-between;
  margin-top: 115px;
  @media (max-width: 1030px) {
    column-gap: 20px;
  }
  @media (max-width: 440px) {
    grid-template-columns: 100%;
    grid-template-rows: repeat(3, 210px);
  }
}

.descriptionBox {
  font-weight: 700;
  font-size: 20px;
  color: #212121;
  padding: 22px 27px;
  display: flex;
  height: 196px;
  background-repeat: no-repeat;
  background: rgba(178, 235, 242, 0.35);
  border-radius: 25px;
  @media (max-width: 1030px) {
    height: auto;
    padding: 15px 20px;
    font-size: 18px;
    border-radius: 15px;
  }
  @media (max-width: 440px) {
    border-radius: 8px;
    font-weight: 400;
    margin-bottom: 20px;
  }
}
